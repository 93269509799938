import { Component, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  static displayLoading: boolean;;
  title = 'MacrosWeb';

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  AppComponent = AppComponent;
  
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
    AppComponent.displayLoading = false;
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    console.log('this.authService.instance.getAllAccounts() app component ', this.authService.instance.getAllAccounts());

    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

    if(this.loginDisplay){
      window.location.reload();
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      console.log('popup');

      if (this.msalGuardConfig.authRequest) {
        console.log('popup1');
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            console.log('popup1 ', response.account);
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        console.log('popup2');
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            console.log('popup2 ', response.account);
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      console.log('no popup');

      if (this.msalGuardConfig.authRequest) {
        console.log('no popup1');
        console.log('no popup1 ', this.msalGuardConfig.authRequest);

        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        console.log('no popup2');

        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
