<div class="panel-form-modal-content">
    <h1>{{currentElement.Proyecto}} - {{currentElement.Agrupacion}} - {{currentElement.NIT}} <br />
        {{currentElement.Nombre}}</h1>

    <form [formGroup]="form" (ngSubmit)="closeModal()">


        <!--
        <div class="form-panel-input" *ngIf="reportType == 5">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Disponibilidad</mat-label>
                <input matInput readonly>
            </mat-form-field>
        </div> -->

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>AuxOrdenesEscrituracion</mat-label>
                <textarea matInput formControlName="AuxOrdenesEscrituracion" maxlength="8000"
                    #AuxOrdenesEscrituracion></textarea>
                <mat-hint align="end">{{AuxOrdenesEscrituracion.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.AuxOrdenesEscrituracion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AuxOrdenesEscrituracion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Frpl</mat-label>
                <mat-select formControlName="Frpl">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextFrp" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('Frpl')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option" *ngFor="let option of frplList | filter : searchTextFrp">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.Frpl">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Frpl')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>QuienFirmaEsfp</mat-label>
                <mat-select formControlName="QuienFirmaEsfp">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextQuienFirmaEsfp" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('QuienFirmaEsfp')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of quienFirmaEsfpList | filter : searchTextQuienFirmaEsfp">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.QuienFirmaEsfp">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('QuienFirmaEsfp')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoEsfpFrpl</mat-label>
                <mat-select formControlName="EstadoEsfpFrpl">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoEsfpFrpl" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoEsfpFrpl')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoEsfpFrplList | filter : searchTextEstadoEsfpFrpl">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoEsfpFrpl">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoEsfpFrpl')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 1 || reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaSeguimientoCoordinador (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaSeguimientoCoordinador"
                    formControlName="FechaSeguimientoCoordinador">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaSeguimientoCoordinador"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaSeguimientoCoordinador></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaSeguimientoCoordinador">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaSeguimientoCoordinador',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 3">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaEstadoCoordinador (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaEstadoCoordinador"
                    formControlName="FechaEstadoCoordinador">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaEstadoCoordinador"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaEstadoCoordinador></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaEstadoCoordinador">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaEstadoCoordinador',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input"
            *ngIf="reportType == 1 || reportType == 2 || reportType == 3 || reportType == 4 || reportType == 5 || reportType == 6 || reportType == 7 || reportType == 8 || reportType == 9 || reportType == 10 || reportType == 11 || reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoCoordinador</mat-label>
                <mat-select formControlName="EstadoCoordinador">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoCoordinador" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoCoordinador')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoCoordinadorList | filter : searchTextEstadoCoordinador">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoCoordinador">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoCoordinador')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input"
            *ngIf="reportType == 1 || reportType == 3 || reportType == 4 || reportType == 6 || reportType == 7 || reportType == 8 || reportType == 9 || reportType == 10">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>ObservacionCoordinador</mat-label>
                <textarea matInput formControlName="ObservacionCoordinador" maxlength="8000"
                    #ObservacionCoordinador></textarea>
                <mat-hint align="end">{{ObservacionCoordinador.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionCoordinador">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionCoordinador')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 2 || reportType == 5 || reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionCoordinador (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerObservacionCoordinador"
                    formControlName="ObservacionCoordinador">
                <mat-datepicker-toggle matSuffix [for]="pickerObservacionCoordinador"></mat-datepicker-toggle>
                <mat-datepicker #pickerObservacionCoordinador></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionCoordinador">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionCoordinador',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>



        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalRenovacion</mat-label>
                <mat-select formControlName="CausalRenovacion">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalRenovacion" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CausalRenovacion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalRenovacionList | filter : searchTextCausalRenovacion">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalRenovacion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalRenovacion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 6">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Comentarios</mat-label>
                <textarea matInput formControlName="Comentarios"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.Comentarios">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Comentarios')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 1 || reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaSeguimientoAnalista (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaSeguimientoAnalista"
                    formControlName="FechaSeguimientoAnalista">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaSeguimientoAnalista"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaSeguimientoAnalista></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaSeguimientoAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaSeguimientoAnalista',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 3">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaEstadoAnalista (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaEstadoAnalista"
                    formControlName="FechaEstadoAnalista">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaEstadoAnalista"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaEstadoAnalista></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaEstadoAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaEstadoAnalista',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input"
            *ngIf="reportType == 1 || reportType == 2 || reportType == 3 || reportType == 4 || reportType == 5 || reportType == 6 || reportType == 7 || reportType == 8 || reportType == 9 || reportType == 10 || reportType == 11 || reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoAnalista</mat-label>
                <mat-select formControlName="EstadoAnalista">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoAnalista" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoAnalista')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoAnalistaList | filter : searchTextEstadoAnalista">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoAnalista')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input"
            *ngIf="reportType == 1 || reportType == 2 || reportType == 3 || reportType == 4 || reportType == 5 || reportType == 6 || reportType == 7 || reportType == 8 || reportType == 9 || reportType == 10 || reportType == 11">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>ObservacionAnalista</mat-label>
                <textarea matInput formControlName="ObservacionAnalista" maxlength="8000"
                    #ObservacionAnalista></textarea>
                <mat-hint align="end">{{ObservacionAnalista.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionAnalista')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 2">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AsignacionAnalistaPromesas</mat-label>
                <textarea matInput formControlName="AsignacionAnalistaPromesas"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.AsignacionAnalistaPromesas">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AsignacionAnalistaPromesas')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 3">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TipoInconsistencia</mat-label>
                <mat-select formControlName="TipoInconsistencia">
                    <div class="search-filter-panel">
        
                        <input class="SearchBox" matInput [(ngModel)]="searchTextTipoInconsistencia" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TipoInconsistencia')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tipoInconsistenciaList | filter : searchTextTipoInconsistencia">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TipoInconsistencia">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TipoInconsistencia')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 3">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ComunicadosEnviados</mat-label>
                <mat-select formControlName="ComunicadosEnviados">
                    <div class="search-filter-panel">
        
                        <input class="SearchBox" matInput [(ngModel)]="searchTextComunicadosEnviados" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ComunicadosEnviados')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of comunicadosEnviadosList | filter : searchTextComunicadosEnviados">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ComunicadosEnviados">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ComunicadosEnviados')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 3">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaCompromiso (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaCompromiso" formControlName="FechaCompromiso">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaCompromiso"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaCompromiso></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaCompromiso">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaCompromiso',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <!-- <div class="form-panel-input" *ngIf="reportType == 3">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoSubsidioMCY</mat-label>
                <mat-select formControlName="EstadoSubsidioMCY">
                    <div class="search-filter-panel">
        
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoSubsidioMCY" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoSubsidioMCY')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoSubsidioMCYList | filter : searchTextEstadoSubsidioMCY">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoSubsidioMCY">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoSubsidioMCY')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <div class="form-panel-input" *ngIf="reportType == 5">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechadeEntrega (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechadeEntrega" formControlName="FechadeEntrega">
                <mat-datepicker-toggle matSuffix [for]="pickerFechadeEntrega"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechadeEntrega></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechadeEntrega">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechadeEntrega',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 5">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>HoradeEntrega</mat-label>
                <mat-select formControlName="HoradeEntrega">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextHoradeEntrega" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('HoradeEntrega')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of horaEntregaList | filter : searchTextHoradeEntrega">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.HoradeEntrega">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('HoradeEntrega')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoPoderAnalista</mat-label>
                <mat-select formControlName="EstadoPoderAnalista">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoPoderAnalista"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoPoderAnalista')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoPoderAnalistaList | filter : searchTextEstadoPoderAnalista">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoPoderAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoPoderAnalista')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TipoDeNovedad</mat-label>
                <mat-select formControlName="TipoDeNovedad">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextTipoDeNovedad" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TipoDeNovedad')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tipoDeNovedadList | filter : searchTextTipoDeNovedad">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TipoDeNovedad">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TipoDeNovedad')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CampanasEspeciales</mat-label>
                <mat-select formControlName="CampanasEspeciales">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCampanasEspeciales"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CampanasEspeciales')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of campanasEspecialesList | filter : searchTextCampanasEspeciales">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CampanasEspeciales">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CampanasEspeciales')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Analista_Outsourcing</mat-label>
                <mat-select formControlName="Analista_Outsourcing">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextAnalista_Outsourcing"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('Analista_Outsourcing')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of analistaOutsourcingList | filter : searchTextAnalista_Outsourcing">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.Analista_Outsourcing">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Analista_Outsourcing')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>



        <div class="form-panel-input" *ngIf="reportType == 1 || reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaAsignacion (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaAsignacion" formControlName="FechaAsignacion">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaAsignacion"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaAsignacion></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaAsignacion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaAsignacion',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaSeguimientoBanco (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaSeguimientoBanco" formControlName="FechaSeguimientoBanco">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaSeguimientoBanco"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaSeguimientoBanco></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaSeguimientoBanco">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaSeguimientoBanco',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoBanco</mat-label>
                <textarea matInput formControlName="EstadoBanco"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoBanco">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoBanco')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionBanco</mat-label>
                <textarea matInput formControlName="ObservacionBanco"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionBanco">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionBanco')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaUltimaNotificacion (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaUltimaNotificacion" formControlName="FechaUltimaNotificacion">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaUltimaNotificacion"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaUltimaNotificacion></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaUltimaNotificacion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaUltimaNotificacion',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NotificacionesNegocio</mat-label>
                <mat-select formControlName="NotificacionesNegocio">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextNotificacionesNegocio" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('NotificacionesNegocio')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of notificacionesNegocioList | filter : searchTextNotificacionesNegocio">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.NotificacionesNegocio">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NotificacionesNegocio')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 10">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>FechaCompromiso (dd-mm-yyyy)</mat-label>
              <input matInput [matDatepicker]="pickerFechaCompromiso" formControlName="FechaCompromiso">
              <mat-datepicker-toggle matSuffix [for]="pickerFechaCompromiso"></mat-datepicker-toggle>
              <mat-datepicker #pickerFechaCompromiso></mat-datepicker>
          </mat-form-field>
          <div *ngIf="!disableValidation.FechaCompromiso">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('FechaCompromiso',1)">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 1">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AsignacionAnalista</mat-label>
                <mat-select formControlName="AsignacionAnalista">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextAsignacionAnalista"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AsignacionAnalista')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of asignacionAnalistaList | filter : searchTextAsignacionAnalista">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AsignacionAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AsignacionAnalista')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 1">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoAnalistaVarado</mat-label>
                <mat-select formControlName="EstadoAnalistaVarado">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoAnalistaVarado"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoAnalistaVarado')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoAnalistaVaradoList | filter : searchTextEstadoAnalistaVarado">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoAnalistaVarado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoAnalistaVarado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>



        <div class="form-panel-input" *ngIf="reportType == 1">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaVarado</mat-label>
                <mat-select formControlName="AnalistaVarado">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextAnalistaVarado"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AnalistaVarado')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of analistaVaradoList | filter : searchTextAnalistaVarado">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaVarado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaVarado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <!-- <div class="form-panel-input" *ngIf="reportType == 1">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaVarado</mat-label>
                <textarea matInput formControlName="AnalistaVarado"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaVarado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaVarado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <div class="form-panel-input" *ngIf="reportType == 1">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaCompromiso (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaCompromiso" formControlName="FechaCompromiso">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaCompromiso"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaCompromiso></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaCompromiso">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaCompromiso',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 1">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Bloqueante</mat-label>
                <textarea matInput formControlName="Bloqueante"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.Bloqueante">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'" (click)="confirm('Bloqueante')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 5">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Arquitecta</mat-label>
                <textarea matInput formControlName="Arquitecta"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.Arquitecta">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Arquitecta')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <!-- <div class="form-panel-input" *ngIf="reportType == 5">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CaidaEntregas</mat-label>
                <mat-select formControlName="CaidaEntregas">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextCaidaEntregas" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CaidaEntregas')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of caidaEntregasList | filter : searchTextCaidaEntregas">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CaidaEntregas">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CaidaEntregas')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <div class="form-panel-input" *ngIf="reportType == 5">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaEntregas</mat-label>
                <mat-select formControlName="AnalistaEntregas">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextAnalistaEntregas" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AnalistaEntregas')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of analistaEntregasList | filter : searchTextAnalistaEntregas">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaEntregas">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaEntregas')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaEnvioSMS (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaEnvioSMS"
                    formControlName="FechaEnvioSMS">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaEnvioSMS"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaEnvioSMS></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaEnvioSMS">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaEnvioSMS',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaNotificacionAlerta (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaNotificacionAlerta"
                    formControlName="FechaNotificacionAlerta">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaNotificacionAlerta"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaNotificacionAlerta></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaNotificacionAlerta">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaNotificacionAlerta',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaCarta1 (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaCarta1"
                    formControlName="FechaCarta1">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaCarta1"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaCarta1></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaCarta1">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaCarta1',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaCarta2 (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaCarta2"
                    formControlName="FechaCarta2">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaCarta2"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaCarta2></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaCarta2">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaCarta2',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaCarta3 (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaCarta3"
                    formControlName="FechaCarta3">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaCarta3"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaCarta3></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaCarta3">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaCarta3',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaAcuerdoProximoPago (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaAcuerdoProximoPago"
                    formControlName="FechaAcuerdoProximoPago">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaAcuerdoProximoPago"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaAcuerdoProximoPago></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaAcuerdoProximoPago">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaAcuerdoProximoPago',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input " *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>MontoPactado</mat-label>
                <input matInput type="number" class="example-right-align" placeholder="0"
                    formControlName="MontoPactado">
                <span matPrefix>$&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
            <div *ngIf="!disableValidation.MontoPactado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('MontoPactado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <!-- <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo1</mat-label>
                <textarea matInput formControlName="LlamadaNo1"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo1">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo1')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo2</mat-label>
                <textarea matInput formControlName="LlamadaNo2"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo2">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo2')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Fenalco1erBarrido</mat-label>
                <textarea matInput formControlName="Fenalco1erBarrido"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.Fenalco1erBarrido">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'" (click)="confirm('Fenalco1erBarrido')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Fenalco2doBarrido</mat-label>
                <textarea matInput formControlName="Fenalco2doBarrido"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.Fenalco2doBarrido">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'" (click)="confirm('Fenalco2doBarrido')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo3</mat-label>
                <textarea matInput formControlName="LlamadaNo3"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo3">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo3')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo4</mat-label>
                <textarea matInput formControlName="LlamadaNo4"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo4">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo4')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <!-- <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo5</mat-label>
                <textarea matInput formControlName="LlamadaNo5"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo5">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo5')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaRecaudo</mat-label>
                <textarea matInput formControlName="AnalistaRecaudo"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaRecaudo">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'" (click)="confirm('AnalistaRecaudo')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <!-- <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoEscriturado</mat-label>
                <mat-select formControlName="EstadoEscriturado">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoEscriturado" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoEscriturado')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoEscrituradoList | filter : searchTextEstadoEscriturado">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoEscriturado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoEscriturado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TipificacionMesAnterior</mat-label>
                <mat-select formControlName="TipificacionMesAnterior">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextTipificacionMesAnterior" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TipificacionMesAnterior')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tipificacionMesAnteriorList | filter : searchTextTipificacionMesAnterior">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TipificacionMesAnterior">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TipificacionMesAnterior')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Operacion</mat-label>
                <mat-select formControlName="Operacion">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextOperacion" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('Operacion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of operacionList | filter : searchTextOperacion">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.Operacion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Operacion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo_1 (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerLlamadaNo_1" formControlName="LlamadaNo_1">
                <mat-datepicker-toggle matSuffix [for]="pickerLlamadaNo_1"></mat-datepicker-toggle>
                <mat-datepicker #pickerLlamadaNo_1></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo_1">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo_1',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo_2 (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerLlamadaNo_2" formControlName="LlamadaNo_2">
                <mat-datepicker-toggle matSuffix [for]="pickerLlamadaNo_2"></mat-datepicker-toggle>
                <mat-datepicker #pickerLlamadaNo_2></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo_2">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo_2',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 7">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LlamadaNo_3 (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerLlamadaNo_3" formControlName="LlamadaNo_3">
                <mat-datepicker-toggle matSuffix [for]="pickerLlamadaNo_3"></mat-datepicker-toggle>
                <mat-datepicker #pickerLlamadaNo_3></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.LlamadaNo_3">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LlamadaNo_3',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 8">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TipificacionCorreccionesCtl</mat-label>
                <mat-select formControlName="TipificacionCorreccionesCtl">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextTipificacionCorreccionesCtl"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TipificacionCorreccionesCtl')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tipificacionCorreccionesCtlList | filter : searchTextTipificacionCorreccionesCtl">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TipificacionCorreccionesCtl">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TipificacionCorreccionesCtl')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 8">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaTrazabilidad</mat-label>
                <textarea matInput formControlName="AnalistaTrazabilidad"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaTrazabilidad">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaTrazabilidad')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoAbogado</mat-label>
                <mat-select formControlName="EstadoAbogado">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoAbogado" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoAbogado')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoAbogadoList | filter : searchTextEstadoAbogado">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoAbogado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoAbogado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionAbogado</mat-label>
                <textarea matInput formControlName="ObservacionAbogado"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionAbogado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionAbogado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>PresentoCambio</mat-label>
                <mat-select formControlName="PresentoCambio">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextPresentoCambio" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('PresentoCambio')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of presentoCambioList | filter : searchTextPresentoCambio">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.PresentoCambio">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('PresentoCambio')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CualFueElCambio</mat-label>
                <textarea matInput formControlName="CualFueElCambio"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.CualFueElCambio">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CualFueElCambio')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NoPredialNacional</mat-label>
                <textarea matInput formControlName="NoPredialNacional"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.NoPredialNacional">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NoPredialNacional')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NumeroId</mat-label>
                <textarea matInput formControlName="NumeroId"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.NumeroId">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NumeroId')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>IdMunicipio</mat-label>
                <textarea matInput formControlName="IdMunicipio"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.IdMunicipio">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('IdMunicipio')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>RadicaciónOrdenPagoPySPredial</mat-label>
                <textarea matInput formControlName="RadicaciónOrdenPagoPySPredial"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.RadicacionOrdenPagoPySPredial">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('RadicaciónOrdenPagoPySPredial')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaEstimadaDePyS (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaEstimadaDePyS" formControlName="FechaEstimadaDePyS">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaEstimadaDePyS"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaEstimadaDePyS></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaEstimadaDePyS">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaEstimadaDePyS',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TipoPyS</mat-label>
                <textarea matInput formControlName="TipoPyS"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.TipoPyS">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TipoPyS')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>MunicipioPyS</mat-label>
                <textarea matInput formControlName="MunicipioPyS"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.MunicipioPyS">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('MunicipioPyS')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoPyS</mat-label>
                <mat-select formControlName="EstadoPyS">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextestadoPyS" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoPyS')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option" *ngFor="let option of estadoPySList | filter : searchTextestadoPyS">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoPyS">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoPyS')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoOrdenes</mat-label>
                <textarea matInput formControlName="EstadoOrdenes"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoOrdenes">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoOrdenes')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaACargoNotaria</mat-label>
                <mat-select formControlName="AnalistaACargoNotaria">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextAnalistaACargoNotaria"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AnalistaACargoNotaria')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of analistaACargoNotariaList | filter : searchTextAnalistaACargoNotaria">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaACargoNotaria">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaACargoNotaria')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 9">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>ObservacionAnalistaACargo</mat-label>
                <textarea matInput formControlName="ObservacionAnalistaACargo" maxlength="8000"
                    #ObservacionAnalistaACargo></textarea>
                <mat-hint align="end">{{ObservacionAnalistaACargo.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionAnalistaACargo">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionAnalistaACargo')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>AnalistaEscrituracion</mat-label>
                <textarea matInput formControlName="AnalistaEscrituracion" maxlength="8000"
                    #AnalistaEscrituracion></textarea>
                <mat-hint align="end">{{AnalistaEscrituracion.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaEscrituracion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaEscrituracion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CaidaEntregas</mat-label>
                <mat-select formControlName="CaidaEntregas">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCaidaEntregas" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CaidaEntregas')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of caidaEntregasList | filter : searchTextCaidaEntregas">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CaidaEntregas">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CaidaEntregas')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TubosDeGestion</mat-label>
                <mat-select formControlName="TubosDeGestion">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextTubosDeGestion"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TubosDeGestion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tubosDeGestionList | filter : searchTextTubosDeGestion">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TubosDeGestion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TubosDeGestion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoPromesas</mat-label>
                <mat-select formControlName="EstadoPromesas">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoPromesas" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoPromesas')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option" *ngFor="let option of estadoPromesasList | filter : searchTextEstadoPromesas">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoPromesas">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoPromesas')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaPredial (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaPredial"
                    formControlName="FechaPredial">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaPredial"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaPredial></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaPredial">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaPredial',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaEstampilla (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaEstampilla"
                    formControlName="FechaEstampilla">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaEstampilla"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaEstampilla></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaEstampilla">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaEstampilla',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaPoderFidubogota (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaPoderFidubogota"
                    formControlName="FechaPoderFidubogota">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaPoderFidubogota"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaPoderFidubogota></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaPoderFidubogota">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaPoderFidubogota',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>RecorridoJuridico</mat-label>
                <mat-select formControlName="RecorridoJuridico">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextRecorridoJuridico" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('RecorridoJuridico')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option" *ngFor="let option of recorridoJuridicoList | filter : searchTextRecorridoJuridico">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.RecorridoJuridico">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('RecorridoJuridico')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ClienteExterior</mat-label>
                <mat-select formControlName="ClienteExterior">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextClienteExterior" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ClienteExterior')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option" *ngFor="let option of clienteExteriorList | filter : searchTextClienteExterior">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ClienteExterior">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ClienteExterior')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaAsigna (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaAsigna" formControlName="FechaAsigna">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaAsigna"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaAsigna></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaAsigna">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaAsigna',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 11">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaMetaComite (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaMetaComite" formControlName="FechaMetaComite">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaMetaComite"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaMetaComite></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaMetaComite">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaMetaComite',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaSubsanado (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaSubsanado"
                    formControlName="FechaSubsanado">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaSubsanado"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaSubsanado></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaSubsanado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaSubsanado',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaSeguimientoAnalista2 (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaSeguimientoAnalista2"
                    formControlName="FechaSeguimientoAnalista2">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaSeguimientoAnalista2"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaSeguimientoAnalista2></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaSeguimientoAnalista2">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaSeguimientoAnalista2',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaInicialSeguimientoAnalista (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaInicialSeguimientoAnalista"
                    formControlName="FechaInicialSeguimientoAnalista">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaInicialSeguimientoAnalista"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaInicialSeguimientoAnalista></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaInicialSeguimientoAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaInicialSeguimientoAnalista',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>PrioridadDelNegocio</mat-label>
                <mat-select formControlName="PrioridadDelNegocio">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextPrioridadDelNegocio" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('PrioridadDelNegocio')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of prioridadDelNegocioList | filter : searchTextPrioridadDelNegocio">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.PrioridadDelNegocio">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('PrioridadDelNegocio')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>
        <!-- <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>PrioridadDelNegocio</mat-label>
                <textarea matInput formControlName="PrioridadDelNegocio" maxlength="8000"
                    #PrioridadDelNegocio></textarea>
                <mat-hint align="end">{{PrioridadDelNegocio.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.PrioridadDelNegocio">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('PrioridadDelNegocio')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <!--  <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>MesDeGestion</mat-label>
                <textarea matInput formControlName="MesDeGestion" maxlength="8000"
                    #MesDeGestion></textarea>
                <mat-hint align="end">{{MesDeGestion.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.MesDeGestion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('MesDeGestion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div> -->

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Notificaciones(Carta)</mat-label>
                <mat-select formControlName="NotificacionesCarta">
                    <div class="search-filter-panel">
        
                        <input class="SearchBox" matInput [(ngModel)]="searchTextNotificacionesCarta" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('NotificacionesCarta')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of notificacionesCartaList | filter : searchTextNotificacionesCarta">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.NotificacionesCarta">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NotificacionesCarta')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ResponsableQueReporta</mat-label>
                <mat-select formControlName="ResponsableQueReporta">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextResponsableQueReporta" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ResponsableQueReporta')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of responsableQueReportaList | filter : searchTextResponsableQueReporta">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ResponsableQueReporta">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ResponsableQueReporta')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Fuente</mat-label>
                <mat-select formControlName="Fuente">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextFuente" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('Fuente')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of fuenteList | filter : searchTextFuente">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.Fuente">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Fuente')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaNegociosEnRiesgo</mat-label>
                <mat-select formControlName="AnalistaNegociosEnRiesgo">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextAnalistaNegociosEnRiesgo" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AnalistaNegociosEnRiesgo')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of analistaNegociosEnRiesgoList | filter : searchTextAnalistaNegociosEnRiesgo">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaNegociosEnRiesgo">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaNegociosEnRiesgo')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaCompromisoAnalista (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaCompromisoAnalista"
                    formControlName="FechaCompromisoAnalista">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaCompromisoAnalista"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaCompromisoAnalista></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaCompromisoAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaCompromisoAnalista',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>DocumentoFirmado</mat-label>
                <mat-select formControlName="DocumentoFirmado">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextDocumentoFirmado" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('DocumentoFirmado')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of documentoFirmadoList | filter : searchTextDocumentoFirmado">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.DocumentoFirmado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('DocumentoFirmado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoDelEncargo</mat-label>
                <mat-select formControlName="EstadoDelEncargo">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoDelEncargo" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoDelEncargo')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoDelEncargoList | filter : searchTextEstadoDelEncargo">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoDelEncargo">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoDelEncargo')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TipoDeClausulaSiAplicaParaEncargo</mat-label>
                <mat-select formControlName="TipoDeClausulaSiAplicaParaEncargo">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextTipoDeClausulaSiAplicaParaEncargo" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TipoDeClausulaSiAplicaParaEncargo')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tipoDeClausulaSiAplicaParaEncargoList | filter : searchTextTipoDeClausulaSiAplicaParaEncargo">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TipoDeClausulaSiAplicaParaEncargo">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TipoDeClausulaSiAplicaParaEncargo')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalFamilia</mat-label>
                <mat-select formControlName="CausalFamilia">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalFamilia" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CausalFamilia')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalFamiliaList | filter : searchTextCausalFamilia">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalFamilia">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalFamilia')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>SubsanadoAl100</mat-label>
                <mat-select formControlName="SubsanadoAl100">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextSubsanadoAl100" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('SubsanadoAl100')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of subsanadoAl100List | filter : searchTextSubsanadoAl100">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.SubsanadoAl100">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('SubsanadoAl100')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Soportes</mat-label>
                <mat-select formControlName="Soportes">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextSoportes" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('Soportes')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of soportesList | filter : searchTextSoportes">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.Soportes">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Soportes')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>ObservacionesDeNegociosEnRiesgo</mat-label>
                <textarea matInput formControlName="ObservacionesDeNegociosEnRiesgo" maxlength="8000"
                    #ObservacionesDeNegociosEnRiesgo></textarea>
                <mat-hint align="end">{{ObservacionesDeNegociosEnRiesgo.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionesDeNegociosEnRiesgo">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionesDeNegociosEnRiesgo')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>BancoDeCreditoNegado</mat-label>
                <textarea matInput formControlName="BancoDeCreditoNegado" maxlength="8000"
                    #BancoDeCreditoNegado></textarea>
                <mat-hint align="end">{{BancoDeCreditoNegado.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.BancoDeCreditoNegado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('BancoDeCreditoNegado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NuevaSolicitudEnOtroBanco</mat-label>
                <mat-select formControlName="NuevaSolicitudEnOtroBanco">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextNuevaSolicitudEnOtroBanco" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('NuevaSolicitudEnOtroBanco')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of nuevaSolicitudEnOtroBancoList | filter : searchTextNuevaSolicitudEnOtroBanco">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.NuevaSolicitudEnOtroBanco">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NuevaSolicitudEnOtroBanco')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>NombreDelBanco</mat-label>
                <textarea matInput formControlName="NombreDelBanco" maxlength="8000"
                    #NombreDelBanco></textarea>
                <mat-hint align="end">{{NombreDelBanco.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.NombreDelBanco">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NombreDelBanco')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input " *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ValorDeSancion</mat-label>
                <input matInput type="number" class="example-right-align" placeholder="0"
                    formControlName="ValorDeSancion">
                <span matPrefix>$&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
            <div *ngIf="!disableValidation.ValorDeSancion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ValorDeSancion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>EstadoParaDesistir</mat-label>
                <textarea matInput formControlName="EstadoParaDesistir" maxlength="8000"
                    #EstadoParaDesistir></textarea>
                <mat-hint align="end">{{EstadoParaDesistir.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoParaDesistir">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoParaDesistir')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>AsesorDelBanco</mat-label>
                <textarea matInput formControlName="AsesorDelBanco" maxlength="8000"
                    #AsesorDelBanco></textarea>
                <mat-hint align="end">{{AsesorDelBanco.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.AsesorDelBanco">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AsesorDelBanco')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalManejoDeAuxiliar</mat-label>
                <mat-select formControlName="CausalManejoDeAuxiliar">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalManejoDeAuxiliar" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CausalManejoDeAuxiliar')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalManejoDeAuxiliarList | filter : searchTextCausalManejoDeAuxiliar">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalManejoDeAuxiliar">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalManejoDeAuxiliar')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>TiempoRespuesta</mat-label>
                <textarea matInput formControlName="TiempoRespuesta" maxlength="8000"
                    #TiempoRespuesta></textarea>
                <mat-hint align="end">{{TiempoRespuesta.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.TiempoRespuesta">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TiempoRespuesta')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 12">
            <mat-form-field class="inputForm" appearance="fill" hintLabel="Max 8000 caracteres">
                <mat-label>CreditoAprobado</mat-label>
                <textarea matInput formControlName="CreditoAprobado" maxlength="8000"
                    #CreditoAprobado></textarea>
                <mat-hint align="end">{{CreditoAprobado.value?.length || 0}}/8000</mat-hint>
            </mat-form-field>
            <div *ngIf="!disableValidation.CreditoAprobado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CreditoAprobado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalDeRiesgo</mat-label>
                <mat-select formControlName="CausalDeRiesgo">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalDeRiesgo" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CausalDeRiesgo')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalDeRiesgoList | filter : searchTextCausalDeRiesgo">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalDeRiesgo">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalDeRiesgo')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalDeRiesgos2</mat-label>
                <mat-select formControlName="CausalDeRiesgos2">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalDeRiesgos2" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CausalDeRiesgos2')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalDeRiesgos2List | filter : searchTextCausalDeRiesgos2">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalDeRiesgos2">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalDeRiesgos2')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalDeRiesgos3</mat-label>
                <mat-select formControlName="CausalDeRiesgos3">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalDeRiesgos3" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('causalDeRiesgos3')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalDeRiesgos3List | filter : searchTextCausalDeRiesgos3">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalDeRiesgos3">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalDeRiesgos3')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalDeRiesgos4</mat-label>
                <mat-select formControlName="CausalDeRiesgos4">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalDeRiesgos4" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('causalDeRiesgos4')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalDeRiesgos4List | filter : searchTextCausalDeRiesgos4">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalDeRiesgos4">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalDeRiesgos4')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>



        <div class="form-panel-input" *ngIf=" reportType == 12">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>EstrategiaDesistimiento</mat-label>
              <mat-select formControlName="EstrategiaDesistimiento">
                  <div class="search-filter-panel">

                      <input class="SearchBox" matInput [(ngModel)]="searchTextEstrategiaDesistimiento" placeholder="Buscar"
                          [ngModelOptions]="{standalone: true}">
                      <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                          (click)="clear('EstrategiaDesistimiento')">
                          close</mat-icon>
                  </div>
                  <mat-option [value]="option"
                      *ngFor="let option of estrategiaDesistimientoList | filter : searchTextEstrategiaDesistimiento">{{option}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="!disableValidation.EstrategiaDesistimiento">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('EstrategiaDesistimiento')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
      </div>

        <div class="form-panel-input" *ngIf=" reportType == 12">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NegocioMetaAnioActual</mat-label>
                <mat-select formControlName="NegocioMetaAnioActual">
                    <div class="search-filter-panel">

                        <input class="SearchBox" matInput [(ngModel)]="searchTextNegocioMetaAnioActual" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('NegocioMetaAnioActual')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of negocioMetaAnioActualList | filter : searchTextNegocioMetaAnioActual">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.NegocioMetaAnioActual">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NegocioMetaAnioActual')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoCoordinador</mat-label>
                <mat-select formControlName="EstadoCoordinador">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoCoordinador" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoCoordinador')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoCoordinadorList | filter : searchTextEstadoCoordinador">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoCoordinador">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoCoordinador')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionCoordinador</mat-label>
                <textarea matInput formControlName="ObservacionCoordinador"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionCoordinador">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionCoordinador')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaGestion (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaGestion" formControlName="FechaGestion">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaGestion"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaGestion></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaGestion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaGestion',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoAnalista</mat-label>
                <mat-select formControlName="EstadoAnalista">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoAnalista" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoAnalista')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoAnalistaList | filter : searchTextEstadoAnalista">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoAnalista')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        
        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionAnalista</mat-label>
                <textarea matInput formControlName="ObservacionAnalista"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionAnalista">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionAnalista')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>



        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TuboGestion</mat-label>
                <mat-select formControlName="TuboGestion">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextTuboGestion" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TuboGestion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tuboGestionList | filter : searchTextTuboGestion">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TuboGestion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TuboGestion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AnalistaAsignado</mat-label>
                <mat-select formControlName="AnalistaAsignado">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextAnalistaAsignado" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AnalistaAsignado')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of analistaAsignadoList | filter : searchTextAnalistaAsignado">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AnalistaAsignado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AnalistaAsignado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaAsignacion (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaAsignacion" formControlName="FechaAsignacion">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaAsignacion"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaAsignacion></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaAsignacion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaAsignacion',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Compromiso</mat-label>
                <textarea matInput formControlName="Compromiso"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.Compromiso">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Compromiso')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaProxGestion (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaProxGestion" formControlName="FechaProxGestion">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaProxGestion"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaProxGestion></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaProxGestion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaProxGestion',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NegocioConCierreFinanciero</mat-label>
                <mat-select formControlName="NegocioConCierreFinanciero">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextNegocioConCierreFinanciero" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('NegocioConCierreFinanciero')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of negocioConCierreFinancieroList | filter : searchTextNegocioConCierreFinanciero">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.NegocioConCierreFinanciero">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NegocioConCierreFinanciero')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>RecursosPropios</mat-label>
                <input matInput type="number" class="example-right-align" placeholder="0"
                    formControlName="RecursosPropios">
                <span matPrefix>$&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
            <div *ngIf="!disableValidation.RecursosPropios">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('RecursosPropios')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>IngresoFamiliar</mat-label>
                <input matInput type="number" class="example-right-align" placeholder="0"
                    formControlName="IngresoFamiliar">
                <span matPrefix>$&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
            <div *ngIf="!disableValidation.IngresoFamiliar">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('IngresoFamiliar')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ClasificacionSisben</mat-label>
                <mat-select formControlName="ClasificacionSisben">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextClasificacionSisben" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ClasificacionSisben')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of clasificacionSisbenList | filter : searchTextClasificacionSisben">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ClasificacionSisben">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ClasificacionSisben')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>SimmularConMCY</mat-label>
                <mat-select formControlName="SimmularConMCY">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextSimmularConMCY" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('SimmularConMCY')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of simmularConMCYList | filter : searchTextSimmularConMCY">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.SimmularConMCY">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('SimmularConMCY')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AccionRecomendada</mat-label>
                <textarea matInput formControlName="AccionRecomendada"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.AccionRecomendada">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AccionRecomendada')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>




        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoSubsidioMCY</mat-label>
                <mat-select formControlName="EstadoSubsidioMCY">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoSubsidioMCY" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoSubsidioMCY')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoSubsidioMCYList | filter : searchTextEstadoSubsidioMCY">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoSubsidioMCY">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoSubsidioMCY')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ValorCreditoAcordado</mat-label>
                <input matInput type="number" class="example-right-align" placeholder="0"
                    formControlName="ValorCreditoAcordado">
                <span matPrefix>$&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
            <div *ngIf="!disableValidation.ValorCreditoAcordado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ValorCreditoAcordado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionCampanaEnrolamientoSisben</mat-label>
                <mat-select formControlName="ObservacionCampanaEnrolamientoSisben">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextObservacionCampanaEnrolamientoSisben" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ObservacionCampanaEnrolamientoSisben')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of observacionCampanaEnrolamientoSisbenList | filter : searchTextObservacionCampanaEnrolamientoSisben">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionCampanaEnrolamientoSisben">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionCampanaEnrolamientoSisben')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AvanceObra (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerAvanceObra" formControlName="AvanceObra">
                <mat-datepicker-toggle matSuffix [for]="pickerAvanceObra"></mat-datepicker-toggle>
                <mat-datepicker #pickerAvanceObra></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.AvanceObra">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AvanceObra',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>


        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>BonoEstrategiaCredito</mat-label>
                <mat-select formControlName="BonoEstrategiaCredito">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextBonoEstrategiaCredito"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('BonoEstrategiaCredito')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of bonoEstrategiaCreditoList | filter : searchTextBonoEstrategiaCredito">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.BonoEstrategiaCredito">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('BonoEstrategiaCredito')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ClienteNoAcepta</mat-label>
                <mat-select formControlName="ClienteNoAcepta">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextClienteNoAcepta"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ClienteNoAcepta')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of clienteNoAceptaList | filter : searchTextClienteNoAcepta">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ClienteNoAcepta">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ClienteNoAcepta')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaInicioEstado (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaInicioEstado" formControlName="FechaInicioEstado">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaInicioEstado"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaInicioEstado></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaInicioEstado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaInicioEstado',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaAgenda (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaAgenda" formControlName="FechaAgenda">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaAgenda"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaAgenda></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaAgenda">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaAgenda',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>Agenda</mat-label>
                <mat-select formControlName="Agenda">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextAgenda" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('Agenda')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of agendaList | filter : searchTextAgenda">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.Agenda">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('Agenda')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>
        
        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>SemaforoSatisfaccion</mat-label>
                <mat-select formControlName="SemaforoSatisfaccion">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextSemaforoSatisfaccion" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('SemaforoSatisfaccion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of semaforoSatisfaccionList | filter : searchTextSemaforoSatisfaccion">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.SemaforoSatisfaccion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('SemaforoSatisfaccion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NumeroRadicadoEnrolamiento</mat-label>
                <textarea matInput formControlName="NumeroRadicadoEnrolamiento"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.NumeroRadicadoEnrolamiento">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NumeroRadicadoEnrolamiento')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaEnrolamiento (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaEnrolamiento" formControlName="FechaEnrolamiento">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaEnrolamiento"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaEnrolamiento></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaEnrolamiento">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaEnrolamiento',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 13">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>PostuladoMCY (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerPostuladoMCY" formControlName="PostuladoMCY">
                <mat-datepicker-toggle matSuffix [for]="pickerPostuladoMCY"></mat-datepicker-toggle>
                <mat-datepicker #pickerPostuladoMCY></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.PostuladoMCY">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('PostuladoMCY',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input"
            *ngIf="reportType == 11 || reportType == 1 || reportType == 2 || reportType == 3 || reportType == 5 || reportType == 7 || reportType == 8 || reportType == 9 || reportType == 10 || reportType == 12 || reportType == 13 ">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaMetaCelula (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaMetaCelula" formControlName="FechaMetaCelula">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaMetaCelula"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaMetaCelula></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaMetaCelula">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaMetaCelula',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

      <div class="form-panel-input" *ngIf="reportType == 14">
        <mat-form-field class="inputForm" appearance="fill">
            <mat-label>Indemnizacion</mat-label>
            <input matInput type="number" class="example-right-align" placeholder="0"
                formControlName="Indemnizacion">
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>.00</span>
        </mat-form-field>
        <div *ngIf="!disableValidation.Indemnizacion">
            <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                (click)="confirm('Indemnizacion')">
                <mat-icon>warning</mat-icon>
            </button>
            <span>Masivo</span>
        </div>
    </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FormaDevolucion</mat-label>
                <mat-select formControlName="FormaDevolucion">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextFormaDevolucion"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('FormaDevolucion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of formaDevolucionList | filter : searchTextFormaDevolucion">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.FormaDevolucion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FormaDevolucion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

            <div class="form-panel-input" *ngIf="reportType == 14">
                <mat-form-field class="inputForm" appearance="fill">
                    <mat-label>Banco</mat-label>
                    <textarea matInput formControlName="Banco"></textarea>
                </mat-form-field>
                <div *ngIf="!disableValidation.Banco">
                    <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                        matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                        (click)="confirm('Banco')">
                        <mat-icon>warning</mat-icon>
                    </button>
                    <span>Masivo</span>
                </div>
            </div>

            <div class="form-panel-input" *ngIf="reportType == 14">
                <mat-form-field class="inputForm" appearance="fill">
                    <mat-label>NumeroCuenta</mat-label>
                    <textarea matInput formControlName="NumeroCuenta"></textarea>
                </mat-form-field>
                <div *ngIf="!disableValidation.NumeroCuenta">
                    <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                        matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                        (click)="confirm('NumeroCuenta')">
                        <mat-icon>warning</mat-icon>
                    </button>
                    <span>Masivo</span>
                </div>
            </div>

            <div class="form-panel-input" *ngIf="reportType == 14">
              <mat-form-field class="inputForm" appearance="fill">
                  <mat-label>TipoCuenta</mat-label>
                  <textarea matInput formControlName="TipoCuenta"></textarea>
              </mat-form-field>
              <div *ngIf="!disableValidation.TipoCuenta">
                  <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                      matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                      (click)="confirm('TipoCuenta')">
                      <mat-icon>warning</mat-icon>
                  </button>
                  <span>Masivo</span>
              </div>
            </div>

          <div class="form-panel-input" *ngIf="reportType == 14">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EntidadEncargadaDevolucion</mat-label>
                <mat-select formControlName="EntidadEncargadaDevolucion">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEntidadEncargadaDevolucion"
                            placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EntidadEncargadaDevolucion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of entidadEncargadaDevolucionList | filter : searchTextEntidadEncargadaDevolucion">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EntidadEncargadaDevolucion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EntidadEncargadaDevolucion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
          </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>EstadoDesistimiento</mat-label>
              <mat-select formControlName="EstadoDesistimiento">
                  <div class="search-filter-panel">
                      <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoDesistimiento"
                          placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                      <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                          (click)="clear('EstadoDesistimiento')">
                          close</mat-icon>
                  </div>
                  <mat-option [value]="option"
                      *ngFor="let option of estadoDesistimientoList | filter : searchTextEstadoDesistimiento">{{option}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="!disableValidation.EstadoDesistimiento">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('EstadoDesistimiento')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>TipoDesistimiento</mat-label>
              <mat-select formControlName="TipoDesistimiento">
                  <div class="search-filter-panel">
                      <input class="SearchBox" matInput [(ngModel)]="searchTextTipoDesistimiento"
                          placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                      <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                          (click)="clear('TipoDesistimiento')">
                          close</mat-icon>
                  </div>
                  <mat-option [value]="option"
                      *ngFor="let option of tipoDesistimientoList | filter : searchTextTipoDesistimiento">{{option}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="!disableValidation.TipoDesistimiento">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('TipoDesistimiento')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>FechaEnvioCertificacionBancaria (dd-mm-yyyy)</mat-label>
              <input matInput [matDatepicker]="pickerFechaEnvioCertificacionBancaria" formControlName="FechaEnvioCertificacionBancaria">
              <mat-datepicker-toggle matSuffix [for]="pickerFechaEnvioCertificacionBancaria"></mat-datepicker-toggle>
              <mat-datepicker #pickerFechaEnvioCertificacionBancaria></mat-datepicker>
          </mat-form-field>
          <div *ngIf="!disableValidation.FechaEnvioCertificacionBancaria">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('FechaEnvioCertificacionBancaria',1)">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>FechaElaboracionMemorando (dd-mm-yyyy)</mat-label>
              <input matInput [matDatepicker]="pickerFechaElaboracionMemorando" formControlName="FechaElaboracionMemorando">
              <mat-datepicker-toggle matSuffix [for]="pickerFechaElaboracionMemorando"></mat-datepicker-toggle>
              <mat-datepicker #pickerFechaElaboracionMemorando></mat-datepicker>
          </mat-form-field>
          <div *ngIf="!disableValidation.FechaElaboracionMemorando">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('FechaElaboracionMemorando',1)">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
      </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>NumeroMemorando</mat-label>
              <textarea matInput formControlName="NumeroMemorando"></textarea>
          </mat-form-field>
          <div *ngIf="!disableValidation.NumeroMemorando">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('NumeroMemorando')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>EstadoFinal</mat-label>
              <mat-select formControlName="EstadoFinal">
                  <div class="search-filter-panel">
                      <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoFinal"
                          placeholder="Buscar" [ngModelOptions]="{standalone: true}">
                      <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                          (click)="clear('EstadoFinal')">
                          close</mat-icon>
                  </div>
                  <mat-option [value]="option"
                      *ngFor="let option of estadoFinalList | filter : searchTextEstadoFinal">{{option}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="!disableValidation.EstadoFinal">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('EstadoFinal')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>Observaciones</mat-label>
              <textarea matInput formControlName="Observaciones"></textarea>
          </mat-form-field>
          <div *ngIf="!disableValidation.Observaciones">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('Observaciones')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>NotaMemoFidudavivienda</mat-label>
              <textarea matInput formControlName="NotaMemoFidudavivienda"></textarea>
          </mat-form-field>
          <div *ngIf="!disableValidation.NotaMemoFidudavivienda">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('NotaMemoFidudavivienda')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>Asistente</mat-label>
              <textarea matInput formControlName="Asistente"></textarea>
          </mat-form-field>
          <div *ngIf="!disableValidation.Asistente">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('Asistente')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 14">
          <mat-form-field class="inputForm" appearance="fill">
              <mat-label>VoboAnalista</mat-label>
              <textarea matInput formControlName="VoboAnalista"></textarea>
          </mat-form-field>
          <div *ngIf="!disableValidation.VoboAnalista">
              <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                  matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                  (click)="confirm('VoboAnalista')">
                  <mat-icon>warning</mat-icon>
              </button>
              <span>Masivo</span>
          </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>NegocioFenalpagare</mat-label>
                <mat-select formControlName="NegocioFenalpagare">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextNegocioFenalpagare" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('NegocioFenalpagare')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of negocioFenalpagareList | filter : searchTextNegocioFenalpagare">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.NegocioFenalpagare">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('NegocioFenalpagare')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CasosAutorizacionEspecial</mat-label>
                <mat-select formControlName="CasosAutorizacionEspecial">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextCasosAutorizacionEspecial" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CasosAutorizacionEspecial')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of casosAutorizacionEspecialList | filter : searchTextCasosAutorizacionEspecial">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CasosAutorizacionEspecial">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CasosAutorizacionEspecial')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ValorBonoDescuento</mat-label>
                <input matInput type="number" class="example-right-align" placeholder="0" formControlName="ValorBonoDescuento">
                <span matPrefix>$&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
            <div *ngIf="!disableValidation.ValorBonoDescuento">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ValorBonoDescuento')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AjusteAplicacionPago</mat-label>
                <mat-select formControlName="AjusteAplicacionPago">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextAjusteAplicacionPago" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AjusteAplicacionPago')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of ajusteAplicacionPagoList | filter : searchTextAjusteAplicacionPago">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AjusteAplicacionPago">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AjusteAplicacionPago')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>LiquidadorGastosEscrituracion</mat-label>
                <textarea matInput formControlName="LiquidadorGastosEscrituracion"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.LiquidadorGastosEscrituracion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('LiquidadorGastosEscrituracion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>TipoSolicitud</mat-label>
                <mat-select formControlName="TipoSolicitud">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextTipoSolicitud" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('TipoSolicitud')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of tipoSolicitudList | filter : searchTextTipoSolicitud">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.TipoSolicitud">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('TipoSolicitud')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaSolicitud (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaSolicitud" formControlName="FechaSolicitud">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaSolicitud"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaSolicitud></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaSolicitud">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaSolicitud',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>
        
        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaRevision (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaRevision" formControlName="FechaRevision">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaRevision"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaRevision></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaRevision">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaRevision',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ProcesoPendienteCartera</mat-label>
                <mat-select formControlName="ProcesoPendienteCartera">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextProcesoPendienteCartera" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ProcesoPendienteCartera')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of procesoPendienteCarteraList | filter : searchTextProcesoPendienteCartera">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ProcesoPendienteCartera">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ProcesoPendienteCartera')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ResponsableCartera</mat-label>
                <mat-select formControlName="ResponsableCartera">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextResponsableCartera" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ResponsableCartera')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of responsableCarteraList | filter : searchTextResponsableCartera">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ResponsableCartera">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ResponsableCartera')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionCartera</mat-label>
                <mat-select formControlName="ObservacionCartera">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextObservacionCartera" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ObservacionCartera')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of observacionCarteraList | filter : searchTextObservacionCartera">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionCartera">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionCartera')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>AplicacionBonoDescuento</mat-label>
                <mat-select formControlName="AplicacionBonoDescuento">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextAplicacionBonoDescuento" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('AplicacionBonoDescuento')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of aplicacionBonoDescuentoList | filter : searchTextAplicacionBonoDescuento">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.AplicacionBonoDescuento">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('AplicacionBonoDescuento')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaObservacionAjustes (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaObservacionAjustes" formControlName="FechaObservacionAjustes">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaObservacionAjustes"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaObservacionAjustes></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaObservacionAjustes">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaObservacionAjustes',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>EstadoPyS</mat-label>
                <mat-select formControlName="EstadoPyS">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextEstadoPyS" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('EstadoPyS')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of estadoPySList | filter : searchTextEstadoPyS">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.EstadoPyS">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('EstadoPyS')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaEvacuadoPyS (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaEvacuadoPyS" formControlName="FechaEvacuadoPyS">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaEvacuadoPyS"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaEvacuadoPyS></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaEvacuadoPyS">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaEvacuadoPyS',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>CausalDevolucion</mat-label>
                <mat-select formControlName="CausalDevolucion">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextCausalDevolucion" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('CausalDevolucion')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of causalDevolucionList | filter : searchTextCausalDevolucion">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.CausalDevolucion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('CausalDevolucion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaNotificacionObsTramites (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaNotificacionObsTramites"
                    formControlName="FechaNotificacionObsTramites">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaNotificacionObsTramites"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaNotificacionObsTramites></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaNotificacionObsTramites">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaNotificacionObsTramites',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ObservacionesAnalistaTramites</mat-label>
                <mat-select formControlName="ObservacionesAnalistaTramites">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextObservacionesAnalistaTramites" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ObservacionesAnalistaTramites')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of observacionesAnalistaTramitesList | filter : searchTextObservacionesAnalistaTramites">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ObservacionesAnalistaTramites">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ObservacionesAnalistaTramites')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>DocumentoGenerado</mat-label>
                <mat-select formControlName="DocumentoGenerado">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextDocumentoGenerado" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('DocumentoGenerado')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of documentoGeneradoList | filter : searchTextDocumentoGenerado">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.DocumentoGenerado">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('DocumentoGenerado')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>ResponsableCarteraDos</mat-label>
                <mat-select formControlName="ResponsableCarteraDos">
                    <div class="search-filter-panel">
                        <input class="SearchBox" matInput [(ngModel)]="searchTextResponsableCarteraDos" placeholder="Buscar"
                            [ngModelOptions]="{standalone: true}">
                        <mat-icon class="close-icon" aria-hidden="false" aria-label="Example home icon"
                            (click)="clear('ResponsableCarteraDos')">
                            close</mat-icon>
                    </div>
                    <mat-option [value]="option"
                        *ngFor="let option of responsableCarteraDosList | filter : searchTextResponsableCarteraDos">{{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="!disableValidation.ResponsableCarteraDos">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('ResponsableCarteraDos')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>FechaDevolucionTramites (dd-mm-yyyy)</mat-label>
                <input matInput [matDatepicker]="pickerFechaDevolucionTramites" formControlName="FechaDevolucionTramites">
                <mat-datepicker-toggle matSuffix [for]="pickerFechaDevolucionTramites"></mat-datepicker-toggle>
                <mat-datepicker #pickerFechaDevolucionTramites></mat-datepicker>
            </mat-form-field>
            <div *ngIf="!disableValidation.FechaDevolucionTramites">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('FechaDevolucionTramites',1)">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 15">
            <mat-form-field class="inputForm" appearance="fill">
                <mat-label>MotivoDevolucion</mat-label>
                <textarea matInput formControlName="MotivoDevolucion"></textarea>
            </mat-form-field>
            <div *ngIf="!disableValidation.MotivoDevolucion">
                <button type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon"
                    matTooltip="Aplicar cambio de forma masiva" [matTooltipPosition]="'before'"
                    (click)="confirm('MotivoDevolucion')">
                    <mat-icon>warning</mat-icon>
                </button>
                <span>Masivo</span>
            </div>
        </div>

        <div class="form-panel-input" *ngIf="reportType == 16">
            <mat-form-field class="inputForm" appearance="fill">
                <span>Aun no hay campos manuales creados para esta herramienta</span>
            </mat-form-field>
        </div>


              <!-- fin -->


        <div>
            <button type="button" mat-raised-button type="submit">Salir</button>
            <button type="button" mat-raised-button color="primary" (click)="saveData()">Guardar</button>
        </div>

    </form>

    <p-confirmDialog header="Confirmacion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <p-dialog id="spinner-panel" [(visible)]="displayLoading" [closeIcon]="false" [showHeader]="false"
        [dismissableMask]="false" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
        [style]="{width: '50vw'}">
        <app-spinner></app-spinner>
    </p-dialog>
</div>
