<!-- <app-header (isShowSidebar)="sidenav.toggle($event)" [isMenuOpened]="isShowSidebar"></app-header> -->
<mat-toolbar>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
        (click)="toggleAction()">
        <mat-icon>menu</mat-icon>
    </button>
    <span>MacrosWeb</span>
    <span class="example-spacer"></span>
    <div class="btns-panel">
        <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon" (click)="logout()">
            <mat-icon>logout</mat-icon> Salir
        </button>
    </div>
</mat-toolbar>


<mat-sidenav-container class="layout">
    <mat-sidenav #sidenav mode="side" [(opened)]="isShowSidebar" class="layout-sidebar"
        [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches">
        <div>
            <div class="avatar-panel">
                <img src="./assets/img/login.png" class="avatar" tabindex="0">
                <h4>Bienvenido!</h4>
            </div>
            <hr color="white" />
            <ng-material-multilevel-menu *ngIf="role != 'NoAutorizado'" [configuration]='config' [items]='appitems'
                (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="layout-content">
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>