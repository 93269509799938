import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter_project'
})
export class FilterProjectPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    
    searchText = searchText.toLowerCase();
        return items.filter( it => {          
          return it.PRY_Nombre.toLowerCase().includes(searchText);
        });
   } 
}