<div class="spinner-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div id="loader">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="loading"></div>
                </div>
            </div>
            <div class="col-12">
                <p class="spinner-message" *ngIf="message && message !== ''">
                    {{message}}
                </p>
            </div>
        </div>
    </div>
</div>
