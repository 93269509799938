import { ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;
  public isShowSidebar: boolean;
  // public isShowSidebar: boolean = false;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  config = {
    paddingAtStart: true,
    fontColor: 'white',
    // selectedListFontColor: '#fee24f',
    selectedListFontColor: 'white',
  };

  appitems = [];

  role = 'NoAutorizado';

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
  ) {
    this.role = localStorage.getItem('role');
    console.log('this.role', this.role);
    this.appitems = [];
    this.appitems = [
      {
        label: 'Herramientas',
        items: [
          {
            label: 'Entregas',
            link: '/tablas/entregas',
          },
          {
            label: 'Escrituracion',
            link: '/tablas/escrituracion',
          },
          {
            label: 'Legalización',
            link: '/tablas/legalizacion',
          },
          {
            label: 'Renovación',
            link: '/tablas/renovaciones',
          },
          // {
          //   label: 'Seguimiento de subsidio',
          //   link: '/tablas/segumientos-subsidio',
          // },
          {
            label: 'Promesas',
            link: '/tablas/promesas',
          },
          //  {
          //   label: 'Desembolso',
          //   link: '/tablas/desembolsos',
          // },
          {
            label: 'Ordenes',
            link: '/tablas/ordenes',
          }, {
            label: 'Subsidio',
            link: '/tablas/subsidios',
          }, {
            label: 'Recaudos',
            link: '/tablas/recaudos',
          }, {
            label: 'Trazabilidad',
            link: '/tablas/trazabilidad',
          },
          {
            label: 'Riesgos',
            link: '/tablas/riesgos',
          },
          {
            label: 'Renegociacion',
            link: '/tablas/renegociacion',
          },
          {
            label: 'Desistimientos',
            link: '/tablas/desistimientos',
          },
          {
            label: 'Paz y Salvos',
            link: '/tablas/pazysalvos',
          },
          {
            label: 'Macro 360',
            link: '/tablas/macro360',
          },
          {
            label: 'Internacional',
            link: '/tablas/internacional',
          }
        ]
      },
      // {
      //   label: 'Reportes',
      //   items: [
      //     {
      //       label: 'Renovación y subsidio',
      //       link: '/reporte/1'
      //     },
      //     {
      //       label: 'Trazabilidad',
      //       // link: '/item-2-2',
      //     },
      //     {
      //       label: 'Seguimiento a Bancos',
      //       // link: '/item-2-2',
      //     },
      //     {
      //       label: 'Ordenes de Escrituración',
      //       // link: '/item-2-2',
      //     },
      //     {
      //       label: 'Escrituración y Entregas',
      //       // link: '/item-2-2',
      //     },
      //     {
      //       label: 'Recaudos',
      //       link: '/reporte/6',
      //     },
      //     {
      //       label: 'Firma Promesas',
      //       link: '/reporte/7',
      //     }
      //   ]
      // }
    ];

    if (this.role == 'Administrador') {
      this.appitems.push({
        label: 'Configuracion',
        items: [
          {
            label: 'Seleccion Proyectos',
            link: '/configuracion-proyectos',
          },
          {
            label: 'Roles de Usuario',
            link: '/configuracion-roles',
          },
          {
            label: 'Carga Datos',
            link: '/carga-informacion',
          },
          {
            label: 'Auditoria',
            link: '/auditoria',
          }
        ]
      });
    }


    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    // this.isShowSidebar = !this.mobileQuery.matches;
  }

  ngafterviewinit() {
    console.log('####ngafterviewinit');
    this.sidenav.close();
  }

  public toggleAction(): void {
    if (this.sidenav.opened) {
      this.sidenav.close();
    } else {
      this.sidenav.open();
    }
  }


  ngOnInit(): void {

    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS || msg.eventType === EventType.LOGOUT_END),
      )
      .subscribe((result: EventMessage) => {
        console.log('result LOGOUT_SUCCESS', result);
        localStorage.removeItem('token');

      });
  }

  setLoginDisplay() {
    console.log('this.authService.instance.getAllAccounts() app component ', this.authService.instance.getAllAccounts());

    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  // logout() {
  //   localStorage.removeItem('token');
  //   this.router.navigate(['login']);
  // }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }


  public ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);

    this.sidenav.close();

    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  selectedItem($evt) {

  }
}
